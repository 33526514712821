import React from "react"
import parse from "html-react-parser"
import { Link } from "gatsby"

const BlogPostThumbnail = props => {
  return (
    <div class="mb-3 px-1" key={props.key}>
      <Link className="blog-thumbnail-link" to={props.blogLink}>
        <img className="mb-6" src={props.imageURL} alt="" />
        <div className="max-w-sm">
          <h2 className="text-gray-900 display-xs font-semibold mb-3">
            {props.blogTitle}
          </h2>
          <div className="mb-6 text-md">{parse(props.blogExcerpt)}</div>
          <div>
            {props.categories.map(category => {
              return <p>{category.name}</p>
            })}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default BlogPostThumbnail
